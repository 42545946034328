import React from "react";
import { NavLink } from "react-router-dom";
import { FaLinkedin, FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { HiHome } from "react-icons/hi";
import { FaGithub } from "react-icons/fa";
import { SiNpm } from "react-icons/si";

export default function Footer(props) {
  const footerSocialMediaComponent = [
    {
      icon: <HiHome />,
      link: "/",
    },
    {
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com/in/nikitamallya/",
    },
    {
      icon: <FaGithub />,
      link: "https://github.com/iamnikitamallya",
    },
    {
      icon: <SiNpm />,
      link: "https://www.npmjs.com/~nikitamallya",
    },
    {
      icon: <FaPhone />,
      link: "tel:+447435090543",
    },
    {
      icon: <FaEnvelope />,
      link: "mailto:mallyanikita165@gmail.com",
    },
    {
      icon: <FaWhatsapp />,
      link: "https://wa.me/447435090543/",
    },
  ];
  return (
    <div className={`footer ${props.mode}`}>
      <div className="container">
        <p className="d-flex justify-content-between">
          <span className="copytext">
            &copy; {new Date().getFullYear()}
            <NavLink to="/"> Nikita S Mallya</NavLink>.
          </span>
          <span className="home">
            {footerSocialMediaComponent.map((item, index) => (
              <span key={index}>
                <NavLink to={item.link}>{item.icon}</NavLink>
              </span>
            ))}
          </span>

          <span className="copytext">
            <a href="mailto:mallyanikita165@gmail.com">
              mallyanikita165@gmail.com
            </a>
          </span>
        </p>
      </div>
    </div>
  );
}
