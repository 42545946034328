import React from "react";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Breadcrumb from "../../common/Breadcrumb";
import img1 from "../../../Assets/img/digital/websuite/1.png"
import img2 from "../../../Assets/img/digital/websuite/2.png"
import img3 from "../../../Assets/img/digital/websuite/3.png"
import img4 from "../../../Assets/img/digital/websuite/4.png"
import img5 from "../../../Assets/img/digital/websuite/5.png"
import img6 from "../../../Assets/img/digital/websuite/6.png"
import img7 from "../../../Assets/img/digital/websuite/7.png"
import img8 from "../../../Assets/img/digital/websuite/8.png"
import webs from "../../../Assets/img/digital/websuite/laptop.png"
import BtnRight from "../../common/ButtonRight";
import brandGuide from "../../../Assets/img/digital/websuite/brand.pdf"

const Websuite = (props) => {

    const imgComponent = [
        {
            img: img1
        },
        {
            img: img2
        },
        {
            img: img3
        },
        {
            img: img4
        },
        {
            img: img5
        },
        {
            img: img6
        },
        {
            img: img7
        },
        {
            img: img8
        }
    ]
  
  return (
    <div className={`detailed-page ${props.mode}`}>
        <div className={`${props.mode}`}>
            <Breadcrumb
            title="Branding & Digital Assets > Web Suite"
            to1="/works/digital-assets"
            label1="Back"
            icon1={<SlArrowLeft />}
            />
        </div>
        <div className="section pt-7 ptb-5 text-center">
            <h1 className="pb-4">Web Suite</h1>
            <div className="row pb-4 justify-content-center">
                <div className="col-md-8">
                    <img src={webs} alt="" />
                </div>
            </div>
            <p>Assisted Web Suite in designing logo for their business</p>
            <p>
              <b>Role</b>: Logo Designer
            </p>
            <div className="row">
                <div className="col-md-12">
                    <BtnRight to={brandGuide} icon={ SlArrowRight} className="primary-button" label="Brand Guideline Document"/>
                </div>
            </div>
            <div className="row pt-4">
                {imgComponent.map((item) => (
                    <div className="col-md-4 my-2">
                        <img src={item.img} alt="" className="rounded-10 w-100"/>
                    </div>
                ))}
            </div>
            
        </div>
    </div>
  );
};

export default Websuite;
