import React, { useEffect, useState } from "react";

export default function Advice() {
  const [advice, setAdvice] = useState("");
  const [count, setCount] = useState(0);

  async function getAdvice() {
    const result = await fetch("https://api.adviceslip.com/advice");
    const data = await result.json();
    setAdvice(data.slip.advice);
    setCount((c) => c + 1);
  }

  useEffect(() => {
    getAdvice();
  }, []);

  return (
    <div className="section ptb-5 text-center common advice">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1>{advice}</h1>
          <button onClick={getAdvice} className="primary-button btn-small">
            Generate Advice
          </button>
          <Message count={count} />
        </div>
      </div>
    </div>
  );
}

function Message(props) {
  return (
    <div className="pt-2">
      <p>You have read {props.count} pieces of advice</p>
      <small>
        Note: Advice is cached for 2 seconds. Any repeat-request within 2
        seconds will return the same piece of advice.
      </small>
    </div>
  );
}
