import React from "react";
import TextLink from "../../Components/common/TextLink";
const ReactJS = () => {
  const syntaxComp = [
    {
      tag: "Install React App",
      syntax: "npx create-react-app my-app",
    },
    {
      tag: "Run React App",
      syntax: "npm run start",
    },
    {
      tag: "Build React App",
      syntax: "npm run build",
    },
  ];
  return (
    <div>
      <div className="section ptb-5">
        <h3>Basic React Commands and Syntax</h3>

        <table className="table table-responsive table-bordered my-3">
          <tbody>
            {syntaxComp.map((item) => (
              <tr>
                <td>{item.tag}</td>
                <td>
                  <strong>
                    <code>{item.syntax}</code>
                  </strong>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <TextLink text="Back to" urlText="Tutorials?" url="/tutorials" />
      </div>
    </div>
  );
};

export default ReactJS;
