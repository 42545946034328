import React from "react";
import BtnRight from "./ButtonRight";

const Breadcrumb = ({ title, to1, label1, icon1, to2, label2, icon2, to3, label3, icon3, to4, label4, icon4 }) => {
  return (
    <>
      <div className="float-icon">
        <div className="float container">
          <div>
            <h3>{title}</h3>
          </div>
          <div className="mob-flex">
            <BtnRight to={to1} label={label1} icon={icon1} />
            <BtnRight to={to2} label={label2} icon={icon2} />
            <BtnRight to={to3} label={label3} icon={icon3} />
            <BtnRight to={to4} label={label4} icon={icon4} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
