import React from "react";
import BtnRight from "../../common/ButtonRight";
import Breadcrumb from "../../common/Breadcrumb";
import Reaching from "../../../Assets/img/mobile/reaching.png";
import Coffee from "../../../Assets/img/mobile/coffee.png";
import Healthcare from "../../../Assets/img/mobile/healthcare.png";
import { Link } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";

export default function MobileApplication(props) {
  const mobileApps = [
    {
      img: Coffee,
      title: "Coffee App",
      subTitle:
        "Application that allows users to order Your Favourite Coffee. Features yet to be enhanced.",
      para: "",
      link: "https://www.figma.com/proto/bgGs9Ex07gRW2ar9KzVYfk/Coffee-Shop-Mobile-App-Design?node-id=421-1221&t=72zZVzYNOy3F3OIM-1&scaling=scale-down&content-scaling=fixed&page-id=2%3A2&starting-point-node-id=421%3A1221",
    },
    {
      img: Healthcare,
      title: "Healthcare App",
      subTitle:
        "Application that allows users to find top doctors, pharmacy & ambulance. Features yet to be enhanced.",
      para: "",
      link: "https://www.figma.com/proto/EtUhniB9uGWHHDEvELJOHm/Healthcare-App?node-id=1-419&t=P7mjx6q0ZCD6uFBO-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A10",
    },
    {
      img: Reaching,
      title: "Location Reaching App",
      subTitle: "A Location Sharing Application.",
      para: "",
      link: "https://www.figma.com/proto/IOpVtjvb8ftwE2bqSnfcZs/Reaching_Location_Sharing_App?type=design&node-id=1-2&t=RBKSGfZDC0ePcCaQ-1&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A2&mode=design",
    },
  ];
  return (
    <div>
      <div className={`${props.mode}`}>
        <Breadcrumb
          title="Mobile Application"
          to1="/works/frontend"
          label1="Front-end Development"
          icon1={<SlArrowRight />}
          to2="/works/emails"
          label2="Responsive Email Design"
          icon2={<SlArrowRight />}
          to3="/works/uxui"
          label3="UX/UI"
          icon3={<SlArrowRight />}
          to4="/works/digital-assets"
          label4="Digital Asset"
          icon4={<SlArrowRight />}
        />
      </div>
      <div className={`section pb-5 pt-7 works ${props.mode}`}>
        <div className="row">
          <div className="col-12">
            {/* Aakruthi Project Start Here*/}
            {mobileApps.map((item) => (
              <div className="row mob-mb-3">
                <div className="col-md-6">
                  <Link to={item.link} rel="noreferrer">
                    <div className="card">
                      <img
                        src={item.img}
                        alt={item.title}
                        className="figmaprototype mobile-app"
                      ></img>
                      {/* <video src={qisetnaVideo} autoPlay loop muted></video> */}
                    </div>
                  </Link>
                </div>
                <div className="col-md-6 d-flex-works">
                  <div className="items">
                    <div className="title">
                      <h3>
                        {item.title} <span>{item.subTitle}</span>
                      </h3>
                      <p>{item.para}</p>
                    </div>
                    <BtnRight
                      to={item.link}
                      label="View"
                      icon={<SlArrowRight />}
                      className="icon primary-button"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
