import React from "react";
import { Link } from "react-router-dom";

function TextLink(props) {
  return (
    <>
      <div>
        <small>
          {props.text} <Link to={props.url}>{props.urlText}</Link>
        </small>
      </div>
    </>
  );
}

export default TextLink;
