import React from "react";
import { Link } from "react-router-dom";
import productEmailOne from "../../../Assets/img/emailers/pe-1.png";
import campaignEmail from "../../../Assets/img/emailers/campaign-email.png";
import movieTicket from "../../../Assets/img/emailers/re1.png";
import accountApproval from "../../../Assets/img/emailers/pe1.png";
import verifyEmail from "../../../Assets/img/emailers/pe2.png";
import backInStock from "../../../Assets/img/emailers/pe3.png";
import pe4 from "../../../Assets/img/emailers/pe4.png";
import newsletterImage from "../../../Assets/img/emailers/newsletter.png";
import welcomeEmailImage from "../../../Assets/img/emailers/welcome.png";
import { SlArrowRight } from "react-icons/sl";
import { FiExternalLink } from "react-icons/fi";
import Breadcrumb from "../../common/Breadcrumb";

const EmailerWorks = (props) => {
  return (
    <div>
      <div className={`${props.mode}`}>
        <Breadcrumb
          title="Responsive Email Design"
          to1="/works/uxui"
          label1="UX UI"
          icon1={<SlArrowRight />}
          to2="/works/frontend"
          label2="Front-end Development"
          icon2={<SlArrowRight />}
          to3="/works/mobile-app"
          label3="Mobile Application"
          icon3={<SlArrowRight />}
          to4="/works/digital-assets"
          label4="Digital Asset"
          icon4={<SlArrowRight />}
        />
      </div>

      <div className={`section pb-5 pt-7 works ${props.mode}`}>
        {/* HTML Emailers Start Here */}
        <div className="row space-2">
          <div className="col-md-4">
            <Link to="/works/emails/campaignemail">
              <div className="card border">
                <img
                  src={campaignEmail}
                  alt="Product Email"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Campaign Email</h4>
                    <FiExternalLink />
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/movie-ticket-booking-confirmation.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={movieTicket}
                  alt="Product Email"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Movie Ticket</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/movie-ticket-booking-confirmation.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/account-approval.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={accountApproval}
                  alt="Newsletter"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Account Approval</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/account-approval.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/verify-email.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={verifyEmail}
                  alt="Welcome Email"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Verify Email</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/verify-email.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/back-in-stock.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={backInStock}
                  alt="Welcome Email"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Back in stock</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/back-in-stock.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/product-email.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={productEmailOne}
                  alt="Product Email"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Product Email</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/product-email.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/newsletter.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={newsletterImage}
                  alt="Newsletter"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Newsletter</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/newsletter.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/welcome-email.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img
                  src={welcomeEmailImage}
                  alt="Welcome Email"
                  className="emailers"
                ></img>

                <div className="items">
                  <div className="title">
                    <h4>Welcome Email</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/welcome-email.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a
              href="https://nikitamallya.info/responsive-emailers/responsive-layout.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="card border">
                <img src={pe4} alt="Product Email" className="emailers"></img>

                <div className="items">
                  <div className="title">
                    <h4>Responsive Layout</h4>
                    <a
                      href="https://nikitamallya.info/responsive-emailers/responsive-layout.html"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink />
                    </a>
                  </div>
                  <div className="tech">
                    <p>
                      Technologies: <span>HTML, CSS</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* HTML Emailers End Here */}
      </div>
    </div>
  );
};

export default EmailerWorks;
