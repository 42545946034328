import "./App.css";
import "./Assets/style/custom.scss";
import "./Assets/style/responsive.scss";
import { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Navbar from "./Components/common/Navbar";
import Footer from "./Components/common/Footer";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Works from "./Pages/Works";
import ReactApps from "./Pages/ReactApplications/ReactApps";
import Advice from "./Pages/ReactApplications/Apps/Advice";

import Tutorials from "./Pages/Tutorials/Tutorials";
import GitCommands from "./Pages/Tutorials/GitCommands";
import ReactJS from "./Pages/Tutorials/ReactJS";

import DigitalAssets from "./Components/works/digital/DigitalAssets";
import Induce from "./Components/works/digital/Induce";
import Inhive from "./Components/works/digital/Inhive";
import LMKTileEmp from "./Components/works/digital/LMKTileEmp";
import Websuite from "./Components/works/digital/Websuite";
import WealthInfinity from "./Components/works/digital/WealthInfinity";
import LMKUC from "./Components/works/digital/LMKUC";

import UXWorks from "./Components/works/uxui/UXUI";
import UIWorks from "./Components/works/frontend/FrontEnd";
import EmailerWorks from "./Components/works/emails/Emailers";
import Aakruthi from "./Components/works/uxui/Aakruthi";

import { GiNightSleep } from "react-icons/gi";
import { IoIosSunny } from "react-icons/io";
import MobileApplication from "./Components/works/mobileApp/MobileApplication";

import Login from "./Components/Login";
import NotFound from "./Components/NotFound";
import ElearningCourses from "./Components/works/elearning/ElearningCourses";
import NPMPackagePublish from "./Pages/Tutorials/NPMPackagePublish";
import CampaignEmail from "./Components/works/emails/CampaignEmail";
// import Alert from "./Components/Alert";

function App(props) {
  const [alert, setAlert] = useState("");
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
  };

  setTimeout(() => {
    setAlert("");
  }, 3000);

  const [appcolor, setAppColor] = useState({
    backgroundColor: "#ffffff",
    color: "#000",
  });
  const [btnIcon, setBtnIcon] = useState(<GiNightSleep />);
  const [mode, setMode] = useState("light");

  const handleMode = () => {
    //toggle button & mode
    if (appcolor.backgroundColor === "#ffffff") {
      setAppColor({
        backgroundColor: "#000",
        color: "#fff",
      });
    }
    if (appcolor.backgroundColor === "#000") {
      setAppColor({
        backgroundColor: "#ffffff",
        color: "#222",
      });
    }
    if (mode === "light") {
      setMode("dark");
      setBtnIcon(<IoIosSunny />);
      showAlert("Dark mode has been enabled", "success");
    } else {
      setMode("light");
      setBtnIcon(<GiNightSleep />);
      showAlert("Light mode has been enabled", "success");
    }
  };

  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div className="App transition" style={appcolor}>
      <Navbar mode={mode} handleMode={handleMode} btnIcon={btnIcon} />
      {/* <ThemeModeBtn /> */}
      {/* <Alert alert={alert} /> */}
      <ScrollToTop />
      <div className="container">
        <Routes>
          {/* <Route path="/" element={<Works mode={mode} />} /> */}
          <Route path="/" element={<Navigate to="/works" />} />
          <Route path="/works" element={<Works mode={mode} />} />
          <Route path="/about" element={<Home mode={mode} />} />
          <Route path="/services-offered" element={<Services mode={mode} />} />

          {/* Small React Applications */}
          <Route path="/react-apps" element={<ReactApps />} />
          <Route path="/react-apps/advice" element={<Advice />} />

          {/* All Tutorials */}
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/tutorials/git-commands" element={<GitCommands />} />
          <Route path="/tutorials/react-js" element={<ReactJS />}></Route>
          <Route
            path="/tutorials/npm-package-publish"
            element={<NPMPackagePublish />}
          />

          {/* UX Paths */}
          <Route path="/works/uxui" element={<UXWorks mode={mode} />} />
          <Route
            path="/works/uxui/aakruthi"
            element={<Aakruthi mode={mode} />}
          />

          {/* E-learning courses Path */}
          <Route
            path="/works/e-learning-courses"
            element={<ElearningCourses mode={mode} />}
          />

          {/* Mobile Application Paths */}
          <Route
            path="/works/mobile-app"
            element={<MobileApplication mode={mode} />}
          />

          <Route path="/works/frontend" element={<UIWorks mode={mode} />} />

          {/* All Emailers */}
          <Route path="/works/emails" element={<EmailerWorks mode={mode} />} />
          <Route path="/works/emails/campaignemail" element={ <CampaignEmail />} />

          {/* Branding and Digital Assets */}
          <Route
            path="/works/digital-assets"
            element={<DigitalAssets mode={mode} />}
          />
          <Route
            path="/works/digital-assets/induce"
            element={<Induce mode={mode} />}
          />
          <Route
            path="/works/digital-assets/inhive"
            element={<Inhive mode={mode} />}
          />
          <Route
            path="/works/digital-assets/lmkTile"
            element={<LMKTileEmp mode={mode} />}
          />
          <Route
            path="/works/digital-assets/websuite"
            element={<Websuite mode={mode} />}
          />
          <Route
            path="/works/digital-assets/wealthInfinity"
            element={<WealthInfinity mode={mode} />}
          />
          <Route
            path="/works/digital-assets/lmkuc"
            element={<LMKUC mode={mode} />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer mode={mode} />
    </div>
  );
}

export default App;
