import React from "react";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Breadcrumb from "../../common/Breadcrumb";
import s1 from "../../../Assets/img/digital/lmk-uc/s1.png"
import s2 from "../../../Assets/img/digital/lmk-uc/s2.png"
import s3 from "../../../Assets/img/digital/lmk-uc/s3.png"
import s4 from "../../../Assets/img/digital/lmk-uc/s4.png"
import s5 from "../../../Assets/img/digital/lmk-uc/s5.png"
import s6 from "../../../Assets/img/digital/lmk-uc/s6.png"
import s7 from "../../../Assets/img/digital/lmk-uc/s7.png"
import s8 from "../../../Assets/img/digital/lmk-uc/s8.png"
import s9 from "../../../Assets/img/digital/lmk-uc/s9.png"
import s10 from "../../../Assets/img/digital/lmk-uc/s10.png"
import s11 from "../../../Assets/img/digital/lmk-uc/s11.png"
import s12 from "../../../Assets/img/digital/lmk-uc/s12.png"
import s13 from "../../../Assets/img/digital/lmk-uc/s13.png"
import s14 from "../../../Assets/img/digital/lmk-uc/s14.png"
import s15 from "../../../Assets/img/digital/lmk-uc/s15.png"
import s16 from "../../../Assets/img/digital/lmk-uc/s16.png"
import s17 from "../../../Assets/img/digital/lmk-uc/s17.png"
import s18 from "../../../Assets/img/digital/lmk-uc/s18.png"
import s19 from "../../../Assets/img/digital/lmk-uc/s19.png"
import s20 from "../../../Assets/img/digital/lmk-uc/s20.png"
import s21 from "../../../Assets/img/digital/lmk-uc/s21.png"
import s22 from "../../../Assets/img/digital/lmk-uc/s22.png"
import s23 from "../../../Assets/img/digital/lmk-uc/s23.png"
import bg1 from "../../../Assets/img/digital/lmk-uc/1.png"
import bg2 from "../../../Assets/img/digital/lmk-uc/2.png"
import bg3 from "../../../Assets/img/digital/lmk-uc/3.png"
import bg4 from "../../../Assets/img/digital/lmk-uc/4.png"
import bg5 from "../../../Assets/img/digital/lmk-uc/5.png"
import bg6 from "../../../Assets/img/digital/lmk-uc/6.png"
import bg7 from "../../../Assets/img/digital/lmk-uc/7.png"
import bg8 from "../../../Assets/img/digital/lmk-uc/8.png"
import bg9 from "../../../Assets/img/digital/lmk-uc/9.png"
import bg10 from "../../../Assets/img/digital/lmk-uc/10.png"
import bc1 from "../../../Assets/img/digital/lmk-uc/vc1front.png"
import bc2 from "../../../Assets/img/digital/lmk-uc/vc1back.png"
import bc3 from "../../../Assets/img/digital/lmk-uc/vc2front.png"
import bc4 from "../../../Assets/img/digital/lmk-uc/vc2back.png"
import bc5 from "../../../Assets/img/digital/lmk-uc/vc3front.png"
import bc6 from "../../../Assets/img/digital/lmk-uc/vc3back.png"
import bg from "../../../Assets/img/digital/lmk-uc/standeebg1.png"
import BtnRight from "../../common/ButtonRight";
import Brand from "../../../Assets/img/digital/lmk-uc/brand.pdf"
import BC from "../../../Assets/img/digital/lmk-uc/business-card.pdf"
import SMC from "../../../Assets/img/digital/lmk-uc/social-media.pdf"
import Standee from "../../../Assets/img/digital/lmk-uc/stande.pdf"

const LMKUC = (props) => {

    const smComponent = [
        {
            img: s1
        },
        {
            img: s2
        },
        {
            img: s3
        },
        {
            img: s4
        },
        {
            img: s5
        },
        {
            img: s6
        },
        {
            img: s7
        },
        {
            img: s8
        },
        {
            img: s9
        },
        {
            img: s10
        },
        {
            img: s11
        },
        {
            img: s12
        },
        {
            img: s13
        },
        {
            img: s14
        },
        {
            img: s15
        },
        {
            img: s16
        },
        {
            img: s17
        },
        {
            img: s18
        },
        {
            img: s19
        },
        {
            img: s20
        },
        {
            img: s21
        },
        {
            img: s22
        },
        {
            img: s23
        },
    ]

    const brandComponent = [
        {
            img: bg1
        },
        {
            img: bg2
        },
        {
            img: bg3
        },
        {
            img: bg4
        },
        {
            img: bg5
        },
        {
            img: bg6
        },
        {
            img: bg7
        },
        {
            img: bg8
        },
        {
            img: bg9
        },
        {
            img: bg10
        },
    ]

    const bcComponent = [
        {
            img: bc1
        },
        {
            img: bc2
        },
        {
            img: bc3
        },
        {
            img: bc4
        },
        {
            img: bc5
        },
        {
            img: bc6
        },
    ]
  
  return (
    <div className={`detailed-page ${props.mode}`}>
        <div className={`${props.mode}`}>
            <Breadcrumb
            title="Branding & Digital Assets > LMK Urban Crescent"
            to1="/works/digital-assets"
            label1="Back"
            icon1={<SlArrowLeft />}
            />
        </div>
        <div className="section pt-7 ptb-5">
            <div className="text-center">
            <h1 className="pb-4">LMK Urban Crescent</h1>
            <div className="row pb-4 justify-content-center">
                <div className="col-md-3">
                    <img src={bg} alt="" />
                </div>
            </div>
            <p>Assisted LMK Urban Crescent in designing logo, business card, standee, social media creatives & web design</p>
            <p>
              <b>Role</b>: Fullstack Designer
            </p>
            <BtnRight
              to={Brand}
              icon={SlArrowRight}
              className="primary-button mx-2"
              label="Brand Guideline"
            />
            <BtnRight
              to={Standee}
              icon={SlArrowRight}
              className="primary-button mx-2"
              label="Standee Design"
            />
            <BtnRight
              to={BC}
              icon={SlArrowRight}
              className="primary-button mx-2"
              label="Business Card"
            />
            <BtnRight
              to={SMC}
              icon={SlArrowRight}
              className="primary-button mx-2"
              label="Social Media Creatives"
            />
            </div>
            <div className="my-3 pt-5">
                <h4 className="text-left">Brand Guideline</h4>
                <div className="row">
                    {brandComponent.map((item) => (
                        <div className="col-md-4 my-2">
                            <img src={item.img} alt="" className="rounded-10 border1 w-100"/>
                        </div>
                    ))}
                </div>
            </div>
            <div className="my-3 pt-5">
                <h4 className="text-left">Business Card</h4>
                <div className="row">
                    {bcComponent.map((item) => (
                        <div className="col-md-3 my-2">
                            <img src={item.img} alt="" className="rounded-10 border1 w-100"/>
                        </div>
                    ))}
                </div>
            </div>
            <div className="my-3 pt-5">
                <h4 className="text-left">Social Media Creatives</h4>
                <div className="row">
                    {smComponent.map((item) => (
                        <div className="col-md-3 my-2">
                            <img src={item.img} alt="" className="rounded-10 border1 w-100"/>
                        </div>
                    ))}
                </div>
            </div>

            <div className="pt-4"><small>Note: I have not used location & contact information of the actual client due to security reasons. </small></div>
            
        </div>
    </div>
  );
};

export default LMKUC;
