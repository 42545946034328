import React from 'react'
import TextLink from '../../Components/common/TextLink';

const GitCommands = () => {
  return (
    <div className="section ptb-5">
      <h3>Basic Git Commands</h3>
      <p>
        Install{" "}
        <a
          href="https://git-scm.com/downloads"
          target="_blank"
          rel="noreferrer"
        >
          GIT
        </a>
      </p>
      <p>'git version' - Check for git version in terminal</p>
      <p>'ssh keygen' - Generate SSH key</p>
      <p>
        'id_rsa.pub' - This file content from .ssh folder to be added in your
        GitHub account in order to connect your Git account with your system
      </p>
      <p>
        'git config --global user.name "FIRST_NAME LAST_NAME"' - Add your name
      </p>
      <p>
        'git config --global user.name "MY_NAME@EXAMPLE.COM"' - Connet your
        Email ID in order to track the activity log of users working on the same
        project
      </p>
      <p>'git init' - Initialise the project</p>
      <p>
        'git status' - Check the status of the files whether it's tracked,
        modified, unmodified, staged
      </p>
      <p>'git add .' - Adds all modified files to staged area</p>
      <p>'git commit -m "Commit Message"' - Commits the file to local repo</p>
      <p>
        'git remote add origin git@github.com:your_repo/your_repo_folder_name' -
        Copy the SSH Clone URL & run the command to add remote repo in your
        local machine
      </p>
      <p>
        'git remote' - Run the command to check if you get origin as the result
      </p>
      <p>
        'git push -u origin master' - Run the command to push all your local
        repo changes to the remote repo in the github.
      </p>
      <p>
        'git config --global alias.st status' - Run the command to use 'git st'
        to check status. It's just alias.
      </p>
      <p>
        'git branch' - Run the command to check the current branch we are on.
      </p>
      <p>
        'git checkout -b source' - Run the command to create & switch to the
        source branch.
      </p>
      <p>'git checkout master' - Run the command to switch master branch.</p>
      <p>
        'git branch -d source' - Run the command to delete the source branch.
      </p>
      <TextLink text="Back to" urlText="Tutorials?" url="/tutorials" />
    </div>
  );
}

export default GitCommands
