import React from "react";
import { Link } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
const Tile = ({ icon, work, link, props }) => {
  return (
    <>
      <Link to={link}>
        <div className="tile">
          <div className="d-flex-between-center ptb-2">
            <div className="d-flex gap-20 icon align-items-center">
              <i style={{ color: "#c82b60" }}>{icon}</i>
              <h2 className="p-0 m-0">{work}</h2>
            </div>
            <SlArrowRight />
          </div>
          <hr />
        </div>
      </Link>
    </>
  );
};

export default Tile;
