import React from "react";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Breadcrumb from "../../common/Breadcrumb";
import img1 from "../../../Assets/img/digital/lmk-tile/1.png";
import img2 from "../../../Assets/img/digital/lmk-tile/2.png";
import img3 from "../../../Assets/img/digital/lmk-tile/3.png";
import img4 from "../../../Assets/img/digital/lmk-tile/4.png";
import img5 from "../../../Assets/img/digital/lmk-tile/5.png";
import img6 from "../../../Assets/img/digital/lmk-tile/6.png";
import img7 from "../../../Assets/img/digital/lmk-tile/7.png";
import img8 from "../../../Assets/img/digital/lmk-tile/8.png";
import img9 from "../../../Assets/img/digital/lmk-tile/9.png";
import img10 from "../../../Assets/img/digital/lmk-tile/10.png";
import img11 from "../../../Assets/img/digital/lmk-tile/11.png";
import s1 from "../../../Assets/img/digital/lmk-tile/s1.png";
import s2 from "../../../Assets/img/digital/lmk-tile/s2.png";
import s3 from "../../../Assets/img/digital/lmk-tile/s3.png";
import s4 from "../../../Assets/img/digital/lmk-tile/s4.png";
import s5 from "../../../Assets/img/digital/lmk-tile/s5.png";
import s6 from "../../../Assets/img/digital/lmk-tile/s6.png";
import s7 from "../../../Assets/img/digital/lmk-tile/s7.png";
import s8 from "../../../Assets/img/digital/lmk-tile/s8.png";
import s9 from "../../../Assets/img/digital/lmk-tile/s9.png";
import s10 from "../../../Assets/img/digital/lmk-tile/s10.png";
import s11 from "../../../Assets/img/digital/lmk-tile/s11.png";
import s12 from "../../../Assets/img/digital/lmk-tile/s12.png";
import s13 from "../../../Assets/img/digital/lmk-tile/s13.png";
import s14 from "../../../Assets/img/digital/lmk-tile/s14.png";
import s15 from "../../../Assets/img/digital/lmk-tile/s15.png";
import BtnRight from "../../common/ButtonRight";
import brandGuide from "../../../Assets/img/digital/lmk-tile/lmk_brand.pdf";
import socialMedia from "../../../Assets/img/digital/lmk-tile/lmk-social-media.pdf";

const LMKTileEmp = (props) => {
  const imgComponent = [
    {
      img: img1,
    },
    {
      img: img2,
    },
    {
      img: img3,
    },
    {
      img: img4,
    },
    {
      img: img5,
    },
    {
      img: img6,
    },
    {
      img: img7,
    },
    {
      img: img8,
    },
    {
      img: img9,
    },
    {
      img: img10,
    },
    {
      img: img11,
    },
  ];

  const social = [
    {
      img: s1,
    },
    {
      img: s2,
    },
    {
      img: s3,
    },
    {
      img: s4,
    },
    {
      img: s5,
    },
    {
      img: s6,
    },
    {
      img: s7,
    },
    {
      img: s8,
    },
    {
      img: s9,
    },
    {
      img: s10,
    },
    {
      img: s11,
    },
    {
      img: s12,
    },
    {
      img: s13,
    },
    {
      img: s14,
    },
    {
      img: s15,
    }
  ];
  return (
    <div className={`detailed-page ${props.mode}`}>
      <div className={`${props.mode}`}>
        <Breadcrumb
          title="Branding & Digital Assets > LMK Tile Emporium"
          to1="/works/digital-assets"
          label1="Back"
          icon1={<SlArrowLeft />}
        />
      </div>
      <div className="section pt-7 ptb-5">
        <div className="row text-center">
          <div className="col-md-12">
            <h1 className="pb-4">LMK Tile Emporium</h1>

            <p>Assisted LMK Tile Emporium in branding and digital designs.</p>
            <p>
              <b>Role</b>: Digital Designer
            </p>
            <BtnRight
              to={brandGuide}
              icon={SlArrowRight}
              className="primary-button mx-2"
              label="Brand Guideline"
            />
            <BtnRight
              to={socialMedia}
              icon={SlArrowRight}
              className="primary-button"
              label="Social Media Creatives"
            />
          </div>
        </div>
        <div className="row text-left pt-5">
          <div className="col-md-12">
            <h2>Brand Guidelines</h2>
          </div>
        </div>
        <div className="row">
          {imgComponent.map((item) => (
            <div className="col-md-4 my-2">
              <img src={item.img} alt="" className="rounded-10 w-100" />
            </div>
          ))}
        </div>

        <div className="row text-left pt-5">
          <div className="col-md-12">
            <h2>Social Media Creatives</h2>
          </div>
        </div>
        <div className="row">
          {social.map((item) => (
            <div className="col-md-3 my-2">
              <img src={item.img} alt="" className="rounded-10 w-100" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LMKTileEmp;
