import React, { useRef, useEffect } from "react";
import {
  FaGithub,
  FaHtml5,
  FaCss3Alt,
  FaReact,
  FaGitAlt,
  FaBootstrap,
  FaFigma,
  FaAws,
  FaWordpress,
} from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { VscVscode } from "react-icons/vsc";
import { TbBrandVscode } from "react-icons/tb";
import { DiJira } from "react-icons/di";
import {
  SiTailwindcss,
  SiAdobexd,
  SiElementor,
  SiGoogleanalytics,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiMysql,
  SiPowerbi,
} from "react-icons/si";
import { BsFiletypeScss } from "react-icons/bs";

const AutoScroll = () => {
  const expIcons = [
    { id: "01", icon: <FaHtml5 />, title: "HTML" },
    { id: "02", icon: <FaCss3Alt />, title: "CSS" },
    { id: "03", icon: <SiTailwindcss />, title: "Tailwind CSS" },
    { id: "04", icon: <BsFiletypeScss />, title: "SCSS" },
    { id: "05", icon: <FaBootstrap />, title: "Bootstrap" },
    { id: "06", icon: <IoLogoJavascript />, title: "Javascript" },
    { id: "07", icon: <FaReact />, title: "React.js" },
    { id: "08", icon: <FaGitAlt />, title: "Git" },
    { id: "09", icon: <FaGithub />, title: "GitHub" },
    { id: "10", icon: <FaFigma />, title: "Figma" },
    { id: "11", icon: <SiAdobexd />, title: "Adobe XD" },
    { id: "12", icon: <SiAdobephotoshop />, title: "Photoshop" },
    { id: "13", icon: <SiAdobeillustrator />, title: "Illustrator" },
    { id: "14", icon: <FaAws />, title: "AWS Cloud" },
    { id: "15", icon: <FaWordpress />, title: "WordPress" },
    { id: "16", icon: <SiElementor />, title: "Elementor" },
    { id: "17", icon: <SiMysql />, title: "MySQL" },
    { id: "18", icon: <SiGoogleanalytics />, title: "Google Analytics" },
    { id: "19", icon: <DiJira />, title: "Agile JIRA" },
    { id: "20", icon: <SiPowerbi />, title: "PowerBI" },
    { id: "21", icon: <TbBrandVscode />, title: "VS Code" },
  ];

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        scrollAmount += 1; // Adjust the value to control the speed
        if (scrollContainer) {
          scrollContainer.scrollLeft = scrollAmount;

          // Check if the scroll has reached the end
          if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
            scrollContainer.scrollLeft = 0; // Reset the scroll position
            scrollAmount = 0;
          }
        }
      }, 20); // Adjust the interval time to control the speed
    };

    startAutoScroll();

    return () => clearInterval(scrollInterval); // Cleanup on unmount
  }, []);

  return (
    <div className="scroll-container" ref={scrollContainerRef}>
      <div className="techicons pb-4 d-flex">
        {expIcons.map((item, index) => (
          <span
            key={index}
            className="d-flex flex-column align-items-center scroll-item"
          >
            <i>{item.icon}</i>
            <small>{item.title}</small>
          </span>
        ))}
        {expIcons.map((item, index) => (
          <span
            key={index}
            className="d-flex flex-column align-items-center scroll-item"
          >
            <i>{item.icon}</i>
            <small>{item.title}</small>
          </span>
        ))}
      </div>
    </div>
  );
};

export default AutoScroll;
