import React from "react";
import { Link } from "react-router-dom";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Breadcrumb from "../../common/Breadcrumb";
import img1 from "../../../Assets/img/digital/inhive/1.png"
import img2 from "../../../Assets/img/digital/inhive/2.png"
import img3 from "../../../Assets/img/digital/inhive/f1.png"
import img4 from "../../../Assets/img/digital/inhive/f2.png"
import BtnRight from "../../common/ButtonRight";
import brandGuide from "../../../Assets/img/digital/inhive/inhive.pdf"

const Inhive = (props) => {

    const imgComponent = [
        {
            img: img1
        },
        {
            img: img2
        },
        {
            img: img3
        },
        {
            img: img4
        }
    ]
  
  return (
    <div className={`detailed-page ${props.mode}`}>
        <div className={`${props.mode}`}>
            <Breadcrumb
            title="Branding & Digital Assets > Induce"
            to1="/works/digital-assets"
            label1="Back"
            icon1={<SlArrowLeft />}
            />
        </div>
        <div className="section pt-7 ptb-5 text-center">
            <h1 className="pb-4">Inhive Automation LLP</h1>
            
            <p>Assisted Inhive Automation in designing posters for web and printable flyers.</p>
            <p>
              <b>Role</b>: Graphic Designer
            </p>
            <div className="row">
                <div className="col-md-12">
                    <BtnRight to={brandGuide} icon={ SlArrowRight} className="primary-button" label="Posters and Flyers"/>
                </div>
            </div>
            <div className="row pt-4">
                {imgComponent.map((item) => (
                    <div className="col-md-6 my-2">
                        <img src={item.img} alt="" className="rounded-10 w-100"/>
                    </div>
                ))}
            </div>
            
        </div>
    </div>
  );
};

export default Inhive;
