import React from "react";
import { Link } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";

const Tutorials = () => {
  const tutorialsComponent = [
    {
      title: "Basic Git Commands",
      link: "/tutorials/git-commands",
    },
    {
      title: "Basic React Commands & Syntax",
      link: "/tutorials/react-js",
    },
    {
      title: "NPM Package Publish",
      link: "/tutorials/npm-package-publish",
    },
  ];
  return (
    <div className="section ptb-5">
      <div className="row space-2 pb-5">
        <div className="col-md-12">
          <h2>Basic Tutorials</h2>
        </div>
      </div>
      <div className="row">
        {tutorialsComponent.map((item, index) => (
          <div className="col-md-4 " key={index}>
            <div className="cards common">
              <h3>{item.title}</h3>
              <Link to={item.link} className="primary-button">
                Start <SlArrowRight />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tutorials;
