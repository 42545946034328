import React from "react";
import { SlArrowLeft } from "react-icons/sl";
import Breadcrumb from "../../common/Breadcrumb";
import img1 from "../../../Assets/img/digital/wealth-infinity/1.png"
import img2 from "../../../Assets/img/digital/wealth-infinity/2.png"
import bg from "../../../Assets/img/digital/wealth-infinity/wealth-infy.png"

const WealthInfinity = (props) => {

    const imgComponent = [
        {
            img: img1
        },
        {
            img: img2
        },
    ]
  
  return (
    <div className={`detailed-page ${props.mode}`}>
        <div className={`${props.mode}`}>
            <Breadcrumb
            title="Branding & Digital Assets > Wealth Infinity"
            to1="/works/digital-assets"
            label1="Back"
            icon1={<SlArrowLeft />}
            />
        </div>
        <div className="section pt-7 ptb-5 text-center">
            <h1 className="pb-4">Wealth Infinity</h1>
            <div className="row pb-4 justify-content-center">
                <div className="col-md-8">
                    <img src={bg} alt="" />
                </div>
            </div>
            <p>Assisted Wealth Infinity in designing Visiting Card, Letter-head & Envelops</p>
            <p>
              <b>Role</b>: Digital Designer
            </p>
            {/* <div className="row">
                <div className="col-md-12">
                    <BtnRight to={brandGuide} icon={ SlArrowRight} className="primary-button" label="Brand Guideline Document"/>
                </div>
            </div> */}
            <div className="row pt-4 bg-light">
                {imgComponent.map((item) => (
                    <div className="col-md-12 my-5">
                        <img src={item.img} alt="" className="rounded-10 w-100"/>
                    </div>
                ))}
            </div>

            <div className="pt-4"><small>Note: I have not used location & contact information of the actual client due to security reasons. </small></div>
            
        </div>
    </div>
  );
};

export default WealthInfinity;
