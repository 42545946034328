import React from "react";
import aakruthi from "../../../Assets/img/frontend/aakruthi.png";
import sociozite from "../../../Assets/img/frontend/sociozite.png"
import lmk from "../../../Assets/img/frontend/lmk.png";
import sps from "../../../Assets/img/frontend/sps.png";
import incubix from "../../../Assets/img/frontend/incubix.png";
import indigo from "../../../Assets/img/frontend/indigo.png";
import bot from "../../../Assets/img/frontend/bot.png";
import manvantara from "../../../Assets/img/frontend/manvantara.png";
import shree from "../../../Assets/img/frontend/shree.png";
import smile from "../../../Assets/img/frontend/smile.png";
import { SlArrowRight } from "react-icons/sl";
import { FiExternalLink } from "react-icons/fi";
import Breadcrumb from "../../common/Breadcrumb";

const UIWorks = (props) => {
  const worksComponent = [
    {
      title: "Aakruthi Healthcare",
      link: "https://aakruthihealthcare.com/",
      img: aakruthi,
      services: "Website",
      tech: "React.js",
    },
    {
      title: "Sociozite Ltd",
      link: "https://sociozite.com/",
      img: sociozite,
      services: "Website, SEO",
      tech: "jQuery, Bootstrap, HTML/CSS",
    },
    {
      title: "LMK Urban Crescent",
      link: "https://lmkurbancrescent.com/",
      img: lmk,
      services: "Website",
      tech: "JS, Bootstrap, HTML/CSS",
    },
    {
      title: "Smile Genius Dental",
      link: "https://smilegeniusdental.com/",
      img: smile,
      services: "Website, SEO",
      tech: "WordPress, HTML/CSS",
    },
    {
      title: "SPS Ventures",
      link: "https://spsventures.co.in/",
      img: sps,
      services: "Website",
      tech: "jQuery, Bootstrap, HTML/CSS",
    },
    {
      title: "Incubix",
      link: "https://incubix.in/",
      img: incubix,
      services: "Website, Graphic Support, SEO",
      tech: "jQuery, Bootstrap, HTML/CSS",
    },
    {
      title: "Indigo Data Services",
      link: "https://indigocomputers.in/",
      img: indigo,
      services: "Website, Graphic Support, SEO",
      tech: "jQuery, Bootstrap, HTML/CSS",
    },
    {
      title: "Shreelakshmi Group",
      link: "https://www.shreelakshmigroup.com/",
      img: shree,
      services: "Website, SEO",
      tech: "jQuery, Bootstrap, HTML/CSS",
    },
    {
      title: "Manvantara News",
      link: "https://manvantaratv.in/",
      img: manvantara,
      services: "Website",
      tech: "WordPress, HTML/CSS",
    },
    {
      title: "Botkraft Staffing",
      link: "https://staffing.botkraft.in/",
      img: bot,
      services: "Website, Graphic Support",
      tech: "jQuery, Bootstrap, HTML/CSS",
    },
  ];
  return (
    <div>
      <div className={`${props.mode}`}>
        <Breadcrumb
          title="Front-end Development"
          to1="/works/uxui"
          label1="UX/UI"
          icon1={<SlArrowRight />}
          to2="/works/emails"
          label2="Responsive Email Design"
          icon2={<SlArrowRight />}
          to3="/works/mobile-app"
          label3="Mobile Application"
          icon3={<SlArrowRight />}
          to4="/works/digital-assets"
          label4="Digital Asset"
          icon4={<SlArrowRight />}
        />
      </div>

      <div className={`section pb-5 pt-7 works ${props.mode}`}>
        {/* UI Projects start here */}
        <div className="row">
          {worksComponent.map((item) => (
            <div className="col-md-4">
              <a href={item.link} target="_blank" rel="noreferrer">
                <div className="card border">
                  <img src={item.img} alt={item.title} className="frontend" />
                  <div className="items">
                    <div className="title">
                      <h4>{item.title}</h4>
                      <a
                        href={item.link}
                        className="icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FiExternalLink />
                      </a>
                    </div>
                    <div className="tech">
                      <p>
                        Services: <span>{item.services}</span>
                      </p>
                      <p>
                        Technologies: <span>{item.tech}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
        {/* UI Projects End here */}
      </div>
    </div>
  );
};

export default UIWorks;
