import React from "react";
import { NavLink } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";

const ReactApps = () => {
  return (
    <div className="section ptb-5 services">
      <div className="row space-2 pb-5">
        <div className="col-md-12">
          <h2>Basic React Apps</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="cards common">
            <h3>Free Advice Generator</h3>
            <NavLink to="/react-apps/advice" className="primary-button">
              Click <SlArrowRight />
            </NavLink>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cards common">
            <h3>TextUtils</h3>
            <NavLink
              to="https://iamnikitamallya.github.io/textifypro/"
              className="primary-button"
              target="_blank"
            >
              Click <SlArrowRight />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactApps;
