import React from "react";
import { Link } from "react-router-dom";
import lofi from "../../../Assets/img/ux-design/aakruthi/lofi.png";
import hifi from "../../../Assets/img/ux-design/aakruthi/hifi.png";
import ideation from "../../../Assets/img/ux-design/aakruthi/ideation.png";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import Breadcrumb from "../../common/Breadcrumb";
const Aakruthi = (props) => {
  const details = [
    {
      heading: "Aakruthi - A Responsive Web Design",
      about:
        "Aakruthi is an Ayurvedic cosmetology clinic founded by Dr. Sushmitha Shiva Kumar, a dedicated practitioner with extensive experience in harnessing the power of Ayurveda for natural beauty care.",
      challenge:
        "Build a Responsive web design for an Ayurvedic cosmetology company which serves patient",
      role: "UX/UI Designer and Frontend Developer",
      ideationContent:
        "I led numerous design sprints and devised a sketch outlining methods to enhance user trust. I developed design components to optimize appointment booking, offer users transparent details on services and a range of treatments available at the clinic, and ensure smooth navigation across the website.",
      ideationImg: `${ideation}`,
      lofiContent:
        "Upon reviewing the UI sketches, I converted approved concepts into Lo-Fi wireframes and constructed a clickable prototype. One challenge we encountered was the diversion of users from booking appointments due to the absence of a call-to-action button on every page. Subsequently, we engaged in discussions with the client and proposed the addition of a call-to-action on each page to encourage user interaction and direct them to the booking form.",
      lofiImg: `${lofi}`,
      hifiContent:
        "Following the review of the Lo-Fi prototype and several iterations, I progressed to the Hi-Fi phase and developed a comprehensive and definitive web prototype design.",
      hifiImg: `${hifi}`,
      validationContent:
        "Following validation of the wireframe with 10 users, unanimous feedback revealed that they found the website highly navigable and comprehensible. With a call-to-action button present on every page, the majority of users were prompted to navigate to the appointment booking form.",
      protoLink:
        "https://www.figma.com/proto/GpMwIaZUNcN64RtXPupWOz/Aakruthi_web_prototype",
    },
  ];
  return (
    <div className={`detailed-page ${props.mode}`}>
      <div className={`${props.mode}`}>
            <Breadcrumb
            title="UX UI > Aakruthi"
            to1="/works/uxui"
            label1="Back"
            icon1={<SlArrowLeft />}
            />
        </div>
      {details.map((item) => (
        <div>
          <div className="section pt-7 ptb-5 text-center">
            <h2>{item.heading}</h2>
            <p>{item.about}</p>
            <p>
              <b>Challenge</b>: {item.challenge}
            </p>
            <p>
              <b>Role</b>: {item.role}
            </p>
          </div>
          <hr></hr>
          <div className="pt-5">
            <h3>Ideation</h3>
            <p>{item.ideationContent}</p>
            <img
              src={item.ideationImg}
              alt="aakruthi-design"
              className="pt-3"
            />
          </div>
          <div className="ptb-5">
            <h3>Lo-Fi Wireframe</h3>
            <p>{item.lofiContent}</p>
            <img src={item.lofiImg} alt="aakruthi-design" className="pt-3" />
          </div>
          <div className="pt-5">
            <h3>Hi-Fi Wireframe</h3>
            <p>{item.hifiContent}</p>
            <Link
              to={item.protoLink}
              target="_blank"
              rel="noreferrer"
              className="primary-button"
            >
              Clickable Prototype <SlArrowRight />
            </Link>
            <img src={item.hifiImg} alt="aakruthi-design" className="pt-4" />
          </div>
          <div className="pt-5">
            <h3>User Validation</h3>
            <p>{item.validationContent}</p>
          </div>
          <div className="pt-4 pb-5 text-center d-flex gap-20">
            <Link to="/works/uxui" className="primary-button prev">
              <SlArrowLeft />
              Back
            </Link>
            <Link
              to={item.protoLink}
              target="_blank"
              rel="noreferrer"
              className="primary-button"
            >
              Clickable Prototype <SlArrowRight />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Aakruthi;
