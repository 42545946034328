import React, { useState } from "react";
import Niki from "../Assets/img/nikita.png";
import {
  FaLinkedin,
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaInstagram,
  FaLaptopCode,
} from "react-icons/fa";
import { ImDownload } from "react-icons/im";
import { FiInfo } from "react-icons/fi";
import { GiGraduateCap } from "react-icons/gi";
import { MdWorkHistory } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaGithub } from "react-icons/fa";


const Home = (props) => {
  const socialLinks = [
    {
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com/in/nikitamallya/",
    },
    {
      icon: <FaGithub />,
      link: "https://github.com/iamnikitamallya",
    },
    {
      icon: <FaPhone />,
      link: "tel:+447435090543",
    },
    {
      icon: <FaEnvelope />,
      link: "mailto:mallyanikita165@gmail.com",
    },
    {
      icon: <FaWhatsapp />,
      link: "https://wa.me/447435090543/",
    },
  ];

  const iconButtons = [
    {
      text: "Download CV",
      icon: <ImDownload />,
      link: "https://drive.google.com/file/d/1NlsAV7cJ7ZfcPeZnj7sDwlKlCO5r-lrs/view",
    },
    {
      text: "Works",
      icon: <FaLaptopCode />,
      link: "/",
    },
  ];

  // Tabs JS stats here
  const [tab, setTab] = useState(1);

  function updateTab(id) {
    setTab(id);
  }
  // Tabs JS ends here

  // Education Object

  const educationContent = [
    {
      year: "2024 - 2025 (Ongoing)",
      course: "Post Graduate Diploma in Software Engineering",
      college:
        "Indian Institute of Skill Development Training (IISDT)",
      location: "Bangalore, India",
    },
    {
      year: "2023 - 2024 (Completed)",
      course: "Executive Post Graduation Program in Data Science",
      college:
        "International Institute of Information Technology, Bangalore (IIIT-B)",
      location: "Bangalore, India",
    },
    {
      year: "2014 - 2018 (Completed)",
      course: "B.E., Computer Science",
      college: "PES Institute of Technology",
      location: "Full-time/Shivamogga, India",
    },
  ];

  const workContent = [
    {
      year: "Nov, 2023 - June, 2024",
      designation: "Volunteer Web Designer (UI UX)",
      company: "Qisetna, London, England, UK",
    },
    {
      year: "Dec, 2022 - Nov 2023",
      designation: "LX Software Engineer",
      company:
        "GP Strategies India, Bangalore (On-site programmer at Cargill India)",
    },
    {
      year: "Feb, 2020 - Nov, 2022",
      designation: "Web Developer",
      company: "SART Pvt. Ltd., Bangalore (previously known as Odopixel)",
    },
    {
      year: "July, 2018 - Oct, 2019",
      designation: "Web Designer",
      company: "Tingg Creation, Bangalore",
    },
  ];

  return (
    <div>
      <div className="section pt-5 pb-5 about">
        <div className="row space-2 home-area ">
          <div className="col-md-9">
            {/* <h2 style={{ padding: "0px", margin: "0px" }}>UX UI Designer</h2> */}
            <p style={{ fontWeight: "500" }}>
              Experienced UI/UX Designer and Front-End Developer with over 5
              years of expertise, adept at collaborating with cross-functional
              teams to research, ideate, design, and validate user experiences.
              Proficient in tools like Figma, and Adobe XD for designing
              E-Learning Courses and web/mobile apps, and skilled in crafting
              robust single-page applications (SPAs) using HTML, CSS,
              JavaScript, and React JS and also experienced in developing
              E-learning courses for Learning & Development (L&D) scope in
              Articulate Storyline 360.
            </p>

            <div
              className="social-icons"
              style={{ padding: "00px 0px 20px 0px" }}
            >
              <div className="">
                <div className="d-flex gap-4">
                  {/* <p className='mb-0'>Connect</p> */}
                  <div className={`social ${props.mode}`}>
                    {socialLinks.map((item, index) => (
                      <a
                        key={index}
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#c82b60" }}
                      >
                        {item.icon}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={`d-flex gap-3 iconbox ${props.mode}`}>
              {iconButtons.map((item, index) => (
                <Link to={item.link} className="primary-button btn-small" key={index}>
                  {item.text} {item.icon}
                </Link>
              ))}
            </div>

            <div className={`pt-4 tab-area ${props.mode}`}>
              <i className="info">
                <FiInfo /> Click on each tab to learn more
              </i>
              <div className="tabs">
                <button
                  className={tab === 1 ? "active" : "disable"}
                  onClick={() => updateTab(1)}
                >
                  Work History
                </button>
                <button
                  className={tab === 2 ? "active" : "disable"}
                  onClick={() => updateTab(2)}
                >
                  Skills
                </button>
                <button
                  className={tab === 3 ? "active" : "disable"}
                  onClick={() => updateTab(3)}
                >
                  Education
                </button>
                <button
                  className={tab === 4 ? "active" : "disable"}
                  onClick={() => updateTab(4)}
                >
                  A little more about me :)
                </button>
              </div>
              <div className="tabs-content">
                <div className={tab === 1 ? "show-tab" : "content"}>
                  <div className="d-flex education">
                    <div className="cont">
                      {workContent.map((item, index) => (
                        <div className="edu" key={index}>
                          <div className="year">{item.year}</div>
                          <div className="edu-area">
                            <div className="course">{item.designation}</div>
                            <div className="college">{item.company}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="icon">
                      <MdWorkHistory />
                    </div>
                  </div>
                </div>
                <div className={tab === 2 ? "show-tab" : "content"}>
                  <div className="d-flex education">
                    <div className="cont">
                      {/* <span>
                        React Js • JS/jQuery • FIGMA • HTML • CSS/SCSS •
                        Bootstrap • Prototyping • Wireframing • Responsive
                        Design • SEO • HTML Email Template design • Social Media
                        Creatives • GIT • Wordpress • Social Media Creatives •
                        Social Media Marketing • Google Analytics • Banner
                        designs • Poster designs • Brochure/Flyer designs •
                        Graphic support
                      </span> */}
                      <div className="row">
                        <div className="col-md-4">
                          <ul>
                            <li>FIGMA / Adobe XD</li>
                            <li>Articulate Storyline</li>
                            <li>JavaScript Libraries (React JS)</li>
                            <li>HTML/CSS/SCSS</li>
                            <li>BootStrap</li>
                            <li>WordPress</li>
                            <li>Responsive Design</li>
                            <li>HTML Email Templates</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <ul>
                            <li>User-Centered Design</li>
                            <li>Wireframing</li>
                            <li>Hi-Fi Prototypes</li>
                            <li>WCAG Guidelines</li>
                            <li>Visual Design</li>
                            <li>Web & Mobile Mockups</li>
                            <li>GitHub</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <ul>
                            <li>AWS S3/ Hostgator/ Siteground</li>
                            <li>Technical SEO</li>
                            <li>Google Analytics</li>
                            <li>Social Media Banners</li>
                            <li>MS Word/MS Excel/ MS PPT</li>
                            <li>MS Power BI</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="icon">
                      <FaLaptopCode />
                    </div>
                  </div>
                </div>
                <div className={tab === 3 ? "show-tab" : "content"}>
                  <div className="d-flex education">
                    <div className="cont">
                      {educationContent.map((item, index) => (
                        <div className="edu" key={index}>
                          <div className="year">{item.year}</div>
                          <div className="edu-area">
                            <div className="course">{item.course}</div>
                            <div className="college">
                              {item.college}
                              <br />
                              {item.location}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="icon">
                      <GiGraduateCap />
                    </div>
                  </div>
                </div>
                <div className={tab === 4 ? "show-tab" : "content"}>
                  <div className="d-flex education">
                    <div className="cont">
                      I live in Bangalore, India.
                      <br />
                      <div>
                        I'm passionate about drawings, paintings, and creative
                        works. Check out my art works on<br></br>{" "}
                        <a
                          href="https://www.instagram.com/colorfulcanvas_1996/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontWeight: "500", color: "#c82b60" }}
                        >
                          <FaInstagram /> colorfulcanvas_1996
                        </a>{" "}
                      </div>
                      <div>
                        I love taking photos and engaging in photography
                        whenever I travel. Follow me on<br></br>{" "}
                        <a
                          href="https://www.instagram.com/niki_traveldiaries/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontWeight: "500", color: "#c82b60" }}
                        >
                          <FaInstagram /> niki_traveldiaries
                        </a>
                      </div>
                      <div>
                        Other Links:{" "}
                        <Link
                          to="/react-apps"
                          style={{ fontWeight: "500", color: "#c82b60" }}
                        >
                          React Apps |
                        </Link>{" "}
                        <Link
                          to="/tutorials"
                          style={{ fontWeight: "500", color: "#c82b60" }}
                        >
                          Tutorials
                        </Link>{" "}
                        - It's not updated completely. I shall work on it soon.
                      </div>
                    </div>
                    <div className="icon">
                      <BsEmojiSmile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <img src={Niki} alt="nikita" className="niki" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
