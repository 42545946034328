import React from "react";
import induce from "../../../Assets/img/digital/induce/induce.png";
import inhive from "../../../Assets/img/digital/inhive/inhive.png";
import lmk from "../../../Assets/img/digital/lmk-tile/laptop.png";
import lmkuc from "../../../Assets/img/digital/lmk-uc/standeebg.png";
import BtnRight from "../../common/ButtonRight";
import Breadcrumb from "../../common/Breadcrumb";
import { Link } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import websuite from "../../../Assets/img/digital/websuite/websuite.png";
import wealthinfy from "../../../Assets/img/digital/wealth-infinity/wealth-infy.png";
const DigitalAssets = (props) => {
  const digitalAssets = [
    {
      img: lmkuc,
      title: "LMK Urban Crescent",
      subTitle: "Logo, Business Card, Standee, Social Media Creatives & Web Design",
      para: "Assisted LMK Urban Crescent in designing logo, business card, standee, social media creatives & web design",
      link: "/works/digital-assets/lmkuc",
    },
    {
      img: induce,
      title: "Induce Tech",
      subTitle: "Logo Design, Brand Guideline & Web Assets",
      para: "Assisted Induce Tech in enhancing their branding by creating a logo, developing brand guidelines, and producing digital assets.",
      link: "/works/digital-assets/induce",
    },
    {
        img: inhive,
        title: "Inhive Automation LLP",
        subTitle: "Posters & Flyers Design",
        para: "Assisted Inhive Automation in designing posters for web and printable flyers.",
        link: "/works/digital-assets/inhive",
      },
      {
        img: lmk,
        title: "LMK Tile Emporium",
        subTitle: "Logo Design, Brand Guideline & Social Media Designs/Creatives",
        para: "Assisted LMK Tile Emporium in branding & digital assets.",
        link: "/works/digital-assets/lmkTile",
      },
      {
        img: wealthinfy,
        title: "Wealth Infinity",
        subTitle: "Stationary Design",
        para: "Assisted Wealth Infinity in designing Visiting Card, Letter-head & Envelops",
        link: "/works/digital-assets/wealthInfinity",
      },
      {
        img: websuite,
        title: "Web Suite",
        subTitle: "Logo Design",
        para: "Assisted Web Suite in designing logo for their business",
        link: "/works/digital-assets/websuite",
      },
  ];
  return (
    <div>
      <div className={`${props.mode}`}>
        <Breadcrumb
          title="Branding & Digital Asset"
          to1="/works/frontend"
          label1="Front-end Development"
          icon1={<SlArrowRight />}
          to2="/works/emails"
          label2="Responsive Email Design"
          icon2={<SlArrowRight />}
          to3="/works/mobile-app"
          label3="Mobile Application"
          icon3={<SlArrowRight />}
          to4="/works/uxui"
          label4="UX UI"
          icon4={<SlArrowRight />}
        />
      </div>
      <div className={`section pb-5 pt-7 works ${props.mode}`}>
        <div className="row">
          <div className="col-12">
            {/* Aakruthi Project Start Here*/}
            {digitalAssets.map((item) => (
              <div className="row mob-mb-3">
                <div className="col-md-6">
                  <Link to={item.link} rel="noreferrer">
                    <div className="card">
                      <img
                        src={item.img}
                        alt={item.title}
                        className="figmaprototype"
                      ></img>
                      {/* <video src={qisetnaVideo} autoPlay loop muted></video> */}
                    </div>
                  </Link>
                </div>
                <div className="col-md-6 d-flex-works">
                  <div className="items">
                    <div className="title">
                      <h3>
                        {item.title} <span>{item.subTitle}</span>
                      </h3>
                      <p>{item.para}</p>
                    </div>
                    <BtnRight
                      to={item.link}
                      label="View"
                      icon={<SlArrowRight />}
                      className="icon primary-button"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalAssets;
