import React, { useState } from "react";

import { IoMenu } from "react-icons/io5";
import { FaCode, FaWhatsapp } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { AiFillInfoCircle } from "react-icons/ai";

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

const Navbar = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Works",
      icon: <FaCode />,
      link: "/",
    },
    {
      text: "About",
      icon: <AiFillInfoCircle />,
      link: "/about",
    },
    {
      text: "Services",
      icon: <FaLaptopCode />,
      link: "/services-offered",
    },
    {
      text: "Connect",
      icon: <FaWhatsapp />,
      link: "https://wa.me/919481815358/",
    },

  ];

  return (
    <div className={`header ${props.mode}`}>
      <nav className="container">
        <div className="nav-logo-container">
          <NavLink to="/">
            <p className={`nav-brand ${props.mode}`}>Nikita Mallya</p>
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <div className={`navbar-links-container ${props.mode}`}>
            <NavLink to="/works" activeclassname="active">
              <i>
                <FaCode />
              </i>{" "}
              Works
            </NavLink>
            <NavLink to="/about" activeclassname="active">
              <i>
                <AiFillInfoCircle />
              </i>{" "}
              About
            </NavLink>
            <NavLink to="/services-offered" activeclassname="active">
              <i>
                <FaLaptopCode />
              </i>{" "}
              Services
            </NavLink>
            <a
              href="https://wa.me/919481815358/"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaWhatsapp />
              </i>{" "}
              Connect
            </a>
          </div>
          <button
            className={`themebtn ${props.mode}`}
            onClick={props.handleMode}
          >
            {props.btnIcon}
          </button>
        </div>
        <div className="navbar-menu-container">
          <IoMenu onClick={() => setOpenMenu(true)} className="menu-bar-icon" />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
            className={`mob-nav-area ${props.mode}`}
          >
            <List className="mob-nav">
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon className="nav-icon">
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText className="men-items">
                      <NavLink to={item.link}>{item.text}</NavLink>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
