import React from "react";

const CardOne = ({ icon, title }) => {
  return (
    <div className="card border">
      <div className="ic">{icon}</div>
      <div className="titl">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default CardOne;
