import React from "react";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaCode } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaMobile } from "react-icons/fa";
import { CgIfDesign } from "react-icons/cg";
import { Text, Card, Button } from "@nikitamallya/react-ui-components";
import { FaLaptop } from "react-icons/fa";
import reactUI from "../../src/Assets/img/reactui.png";
import storyline from "../../src/Assets/img/storyline.png";
import Healthcare from "../../src/Assets/img/mobile/healthcare.png";
import Tile from "../Components/common/Tile";
import AutoScroll from "../Components/AutoScroll";

const Works = (props) => {
  const worksComponent = [
    {
      work: "Mobile Application Design",
      link: "/works/mobile-app",
      icon: <FaMobile />,
    },
    {
      work: "User Experience & User Interface (UX/UI)",
      link: "/works/uxui",
      icon: <MdOutlineDesignServices />,
    },
    {
      work: "Front-end Development Projects",
      link: "/works/frontend",
      icon: <FaCode />,
    },
    {
      work: "Responsive Email Templates",
      link: "/works/emails",
      icon: <MdOutlineMailOutline />,
    },
    {
      work: "Branding & Digital Assets",
      link: "/works/digital-assets",
      icon: <CgIfDesign />,
    },
    {
      work: "E-Learning Courses",
      link: "/works/e-learning-courses",
      icon: <FaLaptop />,
    },
  ];

  return (
    <div className={`${props.mode}`}>
      <Text
        content="Hello there! I'm Nikita Mallya, a UI/UX Designer and Front-end Developer
        with a passion for simplicity."
        variant="h5"
        className="pt-5 m-0"
      />
      <Text
        content="I carry hands-on experience with the tools and technologies listed below."
        variant="h6"
        className="pt-5 pb-2"
      />
      <AutoScroll />

      <Text
        content="Recent Stories"
        variant="h5"
        className="pt-2"
        weight="bold"
      />
      <div className="row pb-4">
        <div className="col-md-4">
          <Card
            title="Healthcare Mobile App"
            description="Application that allows users to find top doctors, pharmacy & ambulance. Features yet to be enhanced."
            // display="flex"
            imgUrl={Healthcare}
            align="center"
          >
            <Button
              title="Figma Prototype"
              size="sm"
              url="https://www.figma.com/proto/EtUhniB9uGWHHDEvELJOHm/Healthcare-App?node-id=1-419&t=P7mjx6q0ZCD6uFBO-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A10"
            />
          </Card>
        </div>
        <div className="col-md-4">
          <Card
            title="React UI Components | NPM Package"
            description="I am currently focused on developing NPM package that allow React developers to use UI components effortlessly."
            // display="flex"
            imgUrl={reactUI}
            align="center"
          >
            <Button
              title="NPM"
              size="sm"
              url="https://www.npmjs.com/package/@nikitamallya/react-ui-components"
            />{" "}
            <Button
              title="GitHub"
              size="sm"
              url="https://github.com/iamnikitamallya/react-ui-components"
            />
          </Card>
        </div>
        <div className="col-md-4">
          <Card
            title="Quiz Module | E-Learning | Articulate Storyline 360"
            description="Currently, I am focused on designing streamlined e-learning quiz modules using Articulate Storyline 360."
            imgUrl={storyline}
            align="center"
          >
            <Button
              title="Quiz Module"
              size="sm"
              url="https://iamnikitamallya.github.io/quiz_storyline_360/"
              className="p-20"
            />
          </Card>
        </div>
      </div>
      <hr />
      <Text
        content="Recent Works"
        variant="h5"
        className="pt-4"
        weight="bold"
      />
      <div className="section pb-5 works">
        <div className="my-works">
          <div className={`section works ${props.mode}`}>
            {worksComponent.map((item, index) => (
              <div className="mb-3" key={index}>
                <Tile icon={item.icon} work={item.work} link={item.link} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
