import React from "react";

function CampaignEmail() {
  return (
    <div>
      <style>
        {`
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          width: 100%;
        }

        table {
          border-collapse: collapse;
          width: 100%;
        }

        th,
        td {
          border: 0px;
          color: #fff;
        }

        .container {
          max-width: 600px;
          margin: 0 auto;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        @media (max-width: 600px) {
          .container {
            width: 100%;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }

          .mob-pb-0 {
            padding-bottom: 0px !important;
          }

          td {
            border: none;
            position: relative;
            display: block;
          }

          .spotlight {
            font-size: 32px !important;
          }

          .top-picks {
            font-size: 24px !important;
          }
        }
        `}
      </style>
      <table
        border="0"
        bgcolor="#fff"
        align="center"
        cellpadding="0"
        cellspacing="0"
        style={{
          maxWidth: "100%",
          backgroundColor: "#fff",
          border: "0.5px solid #ebebeb",
        }}
      >
        <tbody>
          <tr>
            <td>
              <table
                bgColor="#060606"
                cellPadding="16"
                cellSpacing="0"
                className="container"
                style={{
                  maxWidth: "600px",
                  width: "100%",
                  padding: "40px",
                  border: "0.1px solid #88888852",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <table>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                                display: "inline-block",
                                letterSpacing: "1px",
                                color: "#ffffff",
                                paddingLeft: "0px",
                              }}
                            >
                              TICKET ALERT
                            </td>
                            <td
                              style={{
                                paddingRight: "0px",
                                textAlign: "right",
                                float: "right",
                              }}
                            >
                              <a
                                href="https://www.nikitamallya.info/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="https://nikitamallya.info/emailers/product-email-templates/slogo.svg"
                                  alt="Ticketmaster Logo"
                                  style={{ width: "100px" }}
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <img
                        src="https://nikitamallya.info/emailers/product-email-templates/banner.jpg"
                        alt="Spotlight Artist"
                        width="100%"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        fontSize: "52px",
                        fontWeight: "900",
                        textTransform: "uppercase",
                        letterSpacing: "-3px",
                        paddingBottom: "0px",
                        color: "#ffffff",
                      }}
                      className="spotlight"
                    >
                      Spotlight Artist
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ fontSize: "14px", color: "#ffffff" }}
                      className="mob-pb-0"
                    >
                      Wed Sep 10, 2024 • 3:00 PM
                    </td>
                    <td style={{ fontSize: "14px", color: "#ffffff" }}>
                      Crypto Arena • Los Angeles, CA
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ paddingBottom: "40px" }}>
                      <a
                        href="/"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <table cellpadding="12">
                          <tbody>
                            <tr>
                              <td
                                colSpan="2"
                                bgcolor="#024DDF"
                                align="center"
                                style={{
                                  borderRadius: "4px",
                                  textTransform: "uppercase",
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  color: "#ffffff",
                                }}
                              >
                                See Tickets
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      className="top-picks"
                      style={{
                        fontSize: "32px",
                        fontWeight: "900",
                        textTransform: "uppercase",
                        letterSpacing: "-1px",
                        color: "#ffffff",
                      }}
                    >
                      Your Top Picks
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "30px" }}>
                      <img
                        src="https://nikitamallya.info/emailers/product-email-templates/a1.jpg"
                        alt="Artist One"
                        width="300"
                      />
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          paddingTop: "10px",
                          margin: "0px",
                          color: "#ffffff",
                        }}
                      >
                        Artist One
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#ffffff99",
                          margin: "0px",
                        }}
                      >
                        Sep 12, 2023 • Los Angeles, CA
                      </p>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              style={{
                                paddingTop: "10px",
                                borderRadius: "4px",
                              }}
                            >
                              <a
                                href="/"
                                style={{
                                  textDecoration: "none",
                                  color: "#ffffff",
                                }}
                                target="_blank"
                              >
                                <table
                                  cellpadding="10"
                                  align="center"
                                  style={{
                                    borderCollapse: "separate !important",
                                    borderSpacing: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "700",
                                          textTransform: "uppercase",
                                          textDecoration: "none",
                                          color: "#ffffff",
                                          border: "1px solid #8c8c8c",
                                          borderRadius: "4px",
                                        }}
                                        align="center"
                                      >
                                        Find Tickets
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{ paddingBottom: "30px" }}>
                      <img
                        src="https://nikitamallya.info/emailers/product-email-templates/a2.jpg"
                        alt="Artist Two"
                        width="300"
                      />
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          paddingTop: "10px",
                          margin: "0px",
                          color: "#ffffff",
                        }}
                      >
                        Artist Two
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#ffffff99",
                          margin: "0px",
                        }}
                      >
                        Sep 12, 2023 • Los Angeles, CA
                      </p>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              style={{
                                paddingTop: "10px",
                                borderRadius: "4px",
                              }}
                            >
                              <a
                                href="/"
                                style={{
                                  textDecoration: "none",
                                  color: "#ffffff",
                                }}
                                target="_blank"
                              >
                                <table
                                  cellpadding="10"
                                  align="center"
                                  style={{
                                    borderCollapse: "separate !important",
                                    borderSpacing: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "700",
                                          textTransform: "uppercase",
                                          textDecoration: "none",
                                          color: "#ffffff",
                                          border: "1px solid #8c8c8c",
                                          borderRadius: "4px",
                                        }}
                                        align="center"
                                      >
                                        Find Tickets
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "30px" }}>
                      <img
                        src="https://nikitamallya.info/emailers/product-email-templates/a3.jpg"
                        alt="Artist Three"
                        width="300"
                      />
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          paddingTop: "10px",
                          margin: "0px",
                          color: "#ffffff",
                        }}
                      >
                        Artist Three
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#ffffff99",
                          margin: "0px",
                        }}
                      >
                        Sep 12, 2023 • Los Angeles, CA
                      </p>
                      <table>
                        <tr>
                          <td
                            align="center"
                            style={{ paddingTop: "10px", borderRadius: "4px" }}
                          >
                            <a
                              href="/"
                              style={{
                                textDecoration: "none",
                                color: "#ffffff",
                              }}
                              target="_blank"
                            >
                              <table
                                cellpadding="10"
                                align="center"
                                style={{
                                  borderCollapse: "separate !important",
                                  borderSpacing: "0",
                                }}
                              >
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      textTransform: "uppercase",
                                      textDecoration: "none",
                                      color: "#ffffff",
                                      border: "1px solid #8c8c8c",
                                      borderRadius: "4px",
                                    }}
                                    align="center"
                                  >
                                    Find Tickets
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ paddingBottom: "30px" }}>
                      <img
                        src="https://nikitamallya.info/emailers/product-email-templates/a4.jpg"
                        alt="Artist Four"
                        width="300"
                      />
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          paddingTop: "10px",
                          margin: "0px",
                          color: "#ffffff",
                        }}
                      >
                        Artist Four
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#ffffff99",
                          margin: "0px",
                        }}
                      >
                        Sep 12, 2023 • Los Angeles, CA
                      </p>
                      <table>
                        <tr>
                          <td
                            align="center"
                            style={{ paddingTop: "10px", borderRadius: "4px" }}
                          >
                            <a
                              href="/"
                              style={{
                                textDecoration: "none",
                                color: "#ffffff",
                              }}
                              target="_blank"
                            >
                              <table
                                cellpadding="10"
                                align="center"
                                style={{
                                  borderCollapse: "separate !important",
                                  borderSpacing: "0",
                                }}
                              >
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      textTransform: "uppercase",
                                      textDecoration: "none",
                                      color: "#ffffff",
                                      border: "1px solid #8c8c8c",
                                      borderRadius: "4px",
                                    }}
                                    align="center"
                                  >
                                    Find Tickets
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <table>
                        <tr>
                          <td
                            colspan="2"
                            style={{
                              padding: "0.1px",
                              backgroundColor: "#9e9e9eb3",
                            }}
                          ></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                        paddingBottom: "0px",
                      }}
                    >
                      Stay Connected
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="/"
                        style={{ paddingRight: "8px" }}
                        target="_blank"
                      >
                        <img
                          src="https://nikitamallya.info/emailers/product-email-templates/fb.png"
                          alt="Facebook"
                          width="25"
                        />
                      </a>
                      <a
                        href="/"
                        style={{ paddingRight: "8px" }}
                        target="_blank"
                      >
                        <img
                          src="https://nikitamallya.info/emailers/product-email-templates/instagram.png"
                          alt="Instagram"
                          width="25"
                        />
                      </a>
                      <a
                        href="/"
                        style={{ paddingRight: "8px" }}
                        target="_blank"
                      >
                        <img
                          src="https://nikitamallya.info/emailers/product-email-templates/x.png"
                          alt="X (Twitter)"
                          width="25"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        color: "#d1d1d1",
                        paddingBottom: "50px",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "0px",
                          marginRight: "0px",
                          marginTop: "0px",
                          marginBottom: "5px",
                          color: "#d1d1d1",
                        }}
                      >
                        355 Sainte-Catherine West Street, Suite 601, Montreal,
                        Quebec, H3B 1A5
                      </p>
                      © 2024
                      <a
                        href="/"
                        style={{ textDecoration: "none", color: "#d1d1d1" }}
                        target="_blank"
                      >
                        Ticketmaster
                      </a>
                      , All rights reserved.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CampaignEmail;
