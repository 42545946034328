import React from "react";
import {
  FaLaptopCode,
  FaWordpress,
  FaFigma,
  FaRegSmile,
  FaRegEnvelope,
  FaGoogle,
  FaInstagram,
} from "react-icons/fa";
import Breadcrumb from "../Components/common/Breadcrumb";
import { MdDesignServices } from "react-icons/md";

import CardOne from "../Components/common/CardOne";

const Services = (props) => {
  const servicesComponent = [
    {
      icon: <FaLaptopCode />,
      title: "Website Development",
    },
    {
      icon: <FaWordpress />,
      title: "WordPress Design",
    },
    {
      icon: <FaFigma />,
      title: "Web/Mobile App Design (FIGMA)",
    },
    {
      icon: <FaRegSmile />,
      title: "App Wireframe (Balsamiq)",
    },
    {
      icon: <FaRegEnvelope />,
      title: "HTML Emailer Design",
    },
    {
      icon: <FaGoogle />,
      title: "SEO (Technical)",
    },
    {
      icon: <FaInstagram />,
      title: "Social Media Marketing",
    },
    {
      icon: <MdDesignServices />,
      title: "Graphic Support",
    },
  ];
  return (
    <div className={`section pt-7 pb-5 services ${props.mode}`}>
      <div className={`${props.mode}`}>
        <Breadcrumb title="Services Offered" />
      </div>
      <div className="row">
        {servicesComponent.map((item, index) => (
          <div className="col-md-3 col-6" key={index}>
            <CardOne icon={item.icon} title={item.title} />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-12">
          <p>
            Hey!{" "}
            <a
              href="https://wa.me/919481815358/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#c82b60 !important" }}
            >
              <b>Let's talk?</b>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
