import React from "react";
import TextLink from "../../Components/common/TextLink";

const NPMPackagePublish = () => {
  const syntaxComp = [
    {
      tag: "Build Package",
      syntax: "npm run build",
    },
    {
      tag: "Check NPM username",
      syntax: "npm whoami",
    },
    {
      tag: "Login to NPM",
      syntax: "npm login",
    },
    {
      tag: "Publish package",
      syntax: "npm publish --access=public",
    },
    {
      tag: "Initialize a new NPM package",
      syntax: "npm init -y",
    },
    {
        tag: "Install necessary dependencies",
        syntax: "npm install react react-dom prop-types react-router-dom"
    },
  ];
  return (
    <div>
      <div className="section ptb-5">
        <h3>NPM Package Build</h3>

        <table className="table table-responsive table-bordered my-3">
          <tbody>
            {syntaxComp.map((item, index) => (
              <tr key={index}>
                <td>{item.tag}</td>
                <td>
                  <strong>
                    <code>{item.syntax}</code>
                  </strong>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <small>
          Note: Make sure to update version in package.json everytime the new
          version of package is released.
        </small>
      <TextLink text="Back to" urlText="Tutorials?" url="/tutorials"/>
      </div>
    </div>
  );
};

export default NPMPackagePublish;
