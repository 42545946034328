import React from "react";
import qisetnaPrototypeImage from "../../../Assets/img/ux-design/qisetna.png";
import mobilePrototypeImage from "../../../Assets/img/ux-design/cargill.png";
import aakruthi from "../../../Assets/img/ux-design/aakruthi/main.png";
import caravas from "../../../Assets/img/ux-design/caravas.png";
import BtnRight from "../../common/ButtonRight";
import Breadcrumb from "../../common/Breadcrumb";
import { Link } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";

const UXWorks = (props) => {
  const uxDesignList = [
    {
      img: caravas,
      title: "CaravasArts",
      subTitle: "A Responsive Parallax Web Theme",
      para: "CARAVAS strives to achieve sustainable development and comprehension of values of arts and collaboration. We exchange knowledge, resources, ideas and skills with a range of partners in UK and internationally.",
      link: "https://www.figma.com/proto/Ah9mkgDJGfbtpgY0YyU26Z/Caravas-Arts",
    },
    {
      img: aakruthi,
      title: "Aakruthi",
      subTitle: "A Responsive Web Design",
      para: "Aakruthi is an Ayurvedic cosmetology clinic, a dedicated practitioner with extensive experience in harnessing the power of Ayurveda for natural beauty care. We believe that true beauty lies in a holistic approach that nourishes both the inner and outer self.",
      link: "/works/uxui/aakruthi",
    },
    {
      img: qisetnaPrototypeImage,
      title: "Arrows without Bodies (Qisetna)",
      subTitle: "A Responsive Web Design",
      para: "Arrows Without Bodies is an environmental sound/video installation incorporating the key issues pertaining to the subject of the so-called ‘illegal’ immigration, combining practices and techniques from artistic areas ranging from performance, video and sound, and installation.",
      link: "https://www.figma.com/proto/8fSbraTIO033YQvQylj39r/Arrows-without-Bodies---Qisetna",
    },
    {
      img: mobilePrototypeImage,
      title: "Mobile-first approach",
      subTitle: "A Responsive Design",
      para: "A “mobile-first” approach involves designing a desktop site starting with the mobile version, which is then adapted to larger screens",
      link: "https://www.figma.com/proto/fAUDyuB8NhlUoarV9rFJZD/Cargill-website-mockup-(Copy)",
    },
  ];
  return (
    <div>
      <div className={`${props.mode}`}>
        <Breadcrumb
          title="UX/UI"
          to1="/works/frontend"
          label1="Front-end Development"
          icon1={<SlArrowRight />}
          to2="/works/emails"
          label2="Responsive Email Design"
          icon2={<SlArrowRight />}
          to3="/works/mobile-app"
          label3="Mobile Application"
          icon3={<SlArrowRight />}
          to4="/works/digital-assets"
          label4="Digital Asset"
          icon4={<SlArrowRight />}
        />
      </div>
      <div className={`section pb-5 pt-7 works ${props.mode}`}>
        <div className="row">
          <div className="col-12">
            {/* Aakruthi Project Start Here*/}
            {uxDesignList.map((item) => (
              <div className="row mob-mb-3">
                <div className="col-md-6">
                  <Link to={item.link} rel="noreferrer">
                    <div className="card">
                      <img
                        src={item.img}
                        alt={item.title}
                        className="figmaprototype"
                      ></img>
                      {/* <video src={qisetnaVideo} autoPlay loop muted></video> */}
                    </div>
                  </Link>
                </div>
                <div className="col-md-6 d-flex-works">
                  <div className="items">
                    <div className="title">
                      <h3>
                        {item.title} <span>{item.subTitle}</span>
                      </h3>
                      <p>{item.para}</p>
                    </div>
                    <BtnRight
                      to={item.link}
                      label="View"
                      icon={<SlArrowRight />}
                      className="icon primary-button"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UXWorks;
